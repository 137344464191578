<script>
export default {
  data() {
    return {};
  },
  created() {
    this.$store.commit("Nav/SET_PAGE", "terms-of-use");
  },
};
</script>
<template>
  <div class="privacy-policy my-container">
    <h1 class="title">Terms of Use</h1>
    <p>
      By using this website, you agree to abide by our Terms of Use. Your
      eligibility to earn and redeem points via our Rewards portal is subject to
      guidelines outlined in our Privacy Policy and Terms Of Use. We reserve the
      right to end our rewards program at our discretion. Please be aware, our
      Rewards program is intended solely to reward frequent/loyal buyers: it is
      essentially a gift from us - or a privilege, not a right. At our sole
      discretion, we may disqualify any user from participation in our rewards
      program. In such an event, any points balance in the user's account would
      be forfeited. Such action would only be taken by us in very isolated
      cases: for example, actions taken by user we deem hostile or detrimental
      to our business. Points are earned in direct relation to the amount you
      spend on your orders with us on Discogs, and eBay. Points are awarded only
      once an order has been dispatched to you. Please note, that points earned
      for a given order will be withdrawn, if the order will subsequently be
      cancelled, or refunded, for any reason. Items received as part of our
      Rewards program, are non-returnable. We accept no responsibility or
      liability for the items you select in our rewards shop. These are intended
      as gifts - and as such - carry no monetary value or exchange/return
      eligibility. Our reward points carry no cash value, stated, or otherwise
      implied.
    </p>
  </div>
</template>
<style lang="scss" scoped>
@import "@/assets/styles/_components.scss";
@import "@/assets/styles/_mixins.scss";

.privacy-policy {
  font-family: var(--open-sans);
  //   margin-top: 18.4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.my-container {
  margin: 8.8rem 9rem;
}
.title {
  font-family: var(--marker);
  margin-bottom: 3.3rem;
  align-self: left;
  font-size: 3.8rem;
  font-weight: 600;
  color: var(--color-lapis-blue);
}
.policy-heading {
  font-size: 2.3rem;
  font-weight: 600;
  margin-bottom: 2.1rem;
}

p {
  font-size: 1.7rem;
  line-height: 2.5em;
  max-width: 60ch;
  margin-bottom: 2.8rem;
}
</style>
